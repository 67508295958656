export default {
  GET(state, data) {
    state.order = { ...data }
  },
  LIST(state, data) {
    state.orders = data
  },
  LIST_ATTACHMENTS(state, data) {
    state.customerInformationForm.attachments = data
  },
  SET_ORDERS_FORM(state, data) {
    state.orderForm = { ...data }
  },
  SET_ASSET_ITEM(state, data) {
    state.assetItem = { ...data }
  },
  SET_ASSET_ITEM_FROM_SEND_OUT(state, data) {
    state.assetItemFromSendOut = { ...data }
  },
  SET_SERVICE_ORDER_INFORMATION_FORM(state, data) {
    state.serviceOrderInformationForm = { ...data }
  },
  SET_DISPATCH_AND_RETURN_FORM(state, data) {
    state.dispatchAndReturnForm = { ...data }
  },
  SET_NOTES_FORM(state, data) {
    state.notes = { ...data }
  },
  SET_SERVICE_TYPE_ON_CHANGE(state, type) {
    state.serviceType.value = type
  },
  SET_ATTACHMENTS(state, data) {
    state.attachments = data
  },
  SET_OTHER_CHARGES(state, data) {
    state.otherCharges = data
  },
  SET_SERVICES_PERFORMED(state, data) {
    state.servicesPerformed = data
  },
  SET_ON_ERROR(state, data) {
    state.onError = { ...data }
  },
}
