import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { customActionGet } from '@core/utils/utils'

// const endpoint = () => 'orders'
const endpoint = () => 'service-order'
const endpointServiceOrderSendOut = 'send-out'
const endpointServiceOrderSendOutVoid = 'send-out-void'
const endpointServiceOrderInHouse = 'in-house'
const endpointServiceOrderSendOutAccept = 'send-out-accept'
const endpointServiceOrderSendOutUpdate = 'send-out-update'
const endpointServiceOrderInHouseUpdate = 'in-house-update'
const endpointServiceOrderCreateServiceInHouse = 'create-service-in-house'
const endpointServiceOrderCloseServiceInHouse = 'close-service-in-house'
const endpointServiceOrderStateAndStatus = 'state-and-status'
const sendOutBillService = 'send-out-bill-service'
const createBill = 'create-bill'
// const invoiceService = 'invoice'

// create-service-in-house
const Actions = crudActions(endpoint())

const getTestDamagedAsset = ({ commit }, { id, queryParams }) => customActionGet(`inventory-items/${id}/get-by-id`, queryParams, commit, 'SET_ASSET_ITEM')
// eslint-disable-next-line no-unused-vars
const getServiceOrder = ({ commit }, id) => customActionGet(`${endpoint()}/${id}`, null)
// eslint-disable-next-line no-empty-pattern
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)
// eslint-disable-next-line no-empty-pattern
const getCustomerSettings = ({ }) => customActionGet('settings/customers/default')

const sellAsset = (ctx, data) => axios.post(`${endpoint()}/${data.id}/sell-asset`)
export default {
  ...Actions,
  getTestDamagedAsset,
  getCustomer,
  sellAsset,
  getServiceOrder,
  getCustomerSettings,
  'send-out': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderSendOut}`, data),
  'send-out-void': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderSendOutVoid}`, data),
  'in-house': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderInHouse}`, data),
  'send-out-accept': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderSendOutAccept}`, data),
  // 'invoice-service': (ctx, data) => axios.post(`${endpoint()}/${data.get('id')}/${invoiceService}`, data),
  'invoice-service': (ctx, data) => axios.post(`${endpoint()}/${data.get('id')}/${createBill}`, data),
  'send-out-update': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderSendOutUpdate}`, data),
  'in-house-update': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderInHouseUpdate}`, data),
  'create-service-in-house': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderCreateServiceInHouse}`, data),
  'close-service-in-house': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderCloseServiceInHouse}`, data),
  'state-and-status-send-out': (ctx, data) => axios.post(`${endpoint()}/${endpointServiceOrderStateAndStatus}/${endpointServiceOrderSendOut}`, data),
  'send-out-bill-service': (ctx, data) => axios.patch(`${endpoint()}/${sendOutBillService}`, data),
}
