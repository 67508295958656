export default {
  getTotalDataOfForms: state => ({
    order: state.order,
    notes: state.notes,
    ...state.dispatchAndReturnForm,
    serviceType: state.serviceType,
    attachments: state.attachments,
    otherCharges: state.otherCharges,
    assetItem: { ...state.assetItem },
    ...state.serviceOrderInformationForm,
    servicesPerformed: state.servicesPerformed,
  }),
}
