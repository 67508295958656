import mainStore from '@/store'
import serviceModule from '@/store/main/orders/service'
import attachmentModule from '@/store/main/customer/attachment'
import { LEquipmentIcon } from '@core/components/feather-icon/customIcons'
import mainConfig from '../../config'

// Get Order Statuses
const {
  ORDERS_STATUS_READY,
  ORDERS_STATUS_VOID,
  ORDERS_STATUS_NO_ACTION,
  ORDERS_STATUS_IN_PROGRESS,
  ORDERS_STATUS_ATTENTION,
  ORDERS_STATUS_WARNING,
} = mainConfig()
export default function config() {
  // Constants
  const MODULE_NAME = 'service'
  const MODULE_NAME_ATTACHMENT = 'attachment'
  const MODULE_NAME_CLONE = 'cloneData'

  const store = mainStore
  const model = serviceModule
  const modelAttachment = attachmentModule
  const CREATE_PAGE_TITLE_PREFIX = 'Request for Service Quote'
  const CREATE_PAGE_TITLE_SUFFIX = 'Mirrorless'

  const ORDERS_STATUS_READY_STATUS = ORDERS_STATUS_READY.status

  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Request for Quote',
      iconSize: '26',
    },
    {
      icon: 'LEquipmentIcon',
      title: 'Service Order',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Invoice',
      iconSize: '26',
    },
  ]

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
      thStyle: {
        width: '10%',
      },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      thStyle: {
        width: '13%',
      },
      isSortable: true,
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: {
        width: '14%',
      },
      isSortable: true,
    },
    {
      key: 'to',
      label: 'To',
      isSortable: true,
      // thStyle: {
      //   width: '50%',
      // },
    },
    {
      key: 'ship_by',
      label: 'Ship By',
      thStyle: {
        width: '14%',
      },
      isSortable: true,
    },
    {
      key: 'return_by',
      label: 'Return by',
      thStyle: {
        width: '14%',
      },
      isSortable: true,
    },
  ]

  // const orderStatus = [
  //   {
  //     icon: 'LQuoteIconFill',
  //   },
  //   {
  //     icon: 'LSignatureIconFill',
  //   },
  //   {
  //     icon: 'LCreditCardIconFill',
  //   },
  //   {
  //     icon: 'LTruckIcon',
  //   },
  //   {
  //     icon: 'LWarehouseIcon',
  //   },
  //   {
  //     icon: 'LBoxSentIcon',
  //   },
  // ]
  const orderStatus = {
    in_house_service: {
      orderStatus: [
        {
          icon: 'LQuoteIconFill',
        },
        {
          icon: 'LSignatureIconFill',
        },
        {
          icon: 'LEquipmentIcon',
        },
        {
          icon: 'LWarehouseIcon',
        },
        {
          icon: 'LCreditCardIconFill',
        },
      ],
    },
    send_out_for_services: {
      orderStatus: [
        {
          icon: 'LQuoteIconFill',
        },
        {
          icon: 'LSignatureIconFill',
        },
        {
          icon: 'LTruckIcon',
        },
        {
          icon: 'LWarehouseIcon',
        },
        {
          icon: 'LCreditCardIconFill',
        },
      ],
    },
  }

  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '25%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Date',
      thStyle: {
        width: '15%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Type',
      thStyle: {
        width: '10%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: '',
      thStyle: {
        width: '21%',
        fontWeight: 'bold',
      },
    },
  ]

  // Asset === === === =>
  const ASSET_TABLE_TITLE = 'Asset'

  const assetTableColumns = [
    // {
    //   key: 'action',
    //   label: '',
    //   thStyle: { width: '5%', padding: 0 },
    // },
    {
      key: 'warehouse',
      label: 'Warehouse',
      thStyle: { width: '10%', padding: '0 0 0 10px' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '10%', padding: 0, paddingLeft: '22px' },
    },
    {
      key: 'reported_damage',
      label: 'Reported Damage',
      thStyle: { width: '25%', padding: 0 },
    },
    // {
    //   key: 'counter',
    //   label: '',
    //   thStyle: { width: '10%', padding: 0, paddingLeft: '22px' },
    // },
    {
      key: 'type',
      label: 'Type',
      thStyle: { width: '10%', padding: 0 },
    },
    {
      key: 'order',
      label: 'Order',
      thStyle: { width: '8%', padding: 0 },
    },
    {
      key: 'by',
      label: 'By',
      thStyle: { width: '17%', padding: 0 },
    },
    // {
    //   key: 'stage',
    //   label: 'Asset Status',
    //   thStyle: { width: '10%', padding: 0 },
    // },
    {
      key: 'bill',
      label: 'Bill',
      thStyle: { width: '2%', padding: 0, paddingRight: '10px' },
    },
  ]

  const STATE_RFQ = 'request_for_quote'
  const STATE_SO = 'service_order'
  const STATE_IN_HOUSE_SERVICE = 'in_house_service'
  const STATE_INVOICE = 'invoice'

  const STATUS_NO_ACTION = ORDERS_STATUS_NO_ACTION.id
  const STATUS_IN_PROGRESS = ORDERS_STATUS_IN_PROGRESS.id
  const STATUS_ATTENTION = ORDERS_STATUS_ATTENTION.id
  const STATUS_WARNING = ORDERS_STATUS_WARNING.id
  const STATUS_READY = ORDERS_STATUS_READY.id
  const STATUS_VOID = ORDERS_STATUS_VOID.id

  const SERVICE_TYPE_OUT = 'send_out_for_services'
  const SERVICE_TYPE_IN = 'in_house_service'

  return {
    store,
    model,
    STATE_SO,
    STATE_RFQ,
    orderStatus,
    MODULE_NAME,
    STATUS_VOID,
    tableColumns,
    STATUS_READY,
    STATE_INVOICE,
    quoteStatusBar,
    STATUS_WARNING,
    modelAttachment,
    SERVICE_TYPE_IN,
    STATUS_NO_ACTION,
    STATUS_ATTENTION,
    SERVICE_TYPE_OUT,
    MODULE_NAME_CLONE,
    ASSET_TABLE_TITLE,
    assetTableColumns,
    STATUS_IN_PROGRESS,
    STATE_IN_HOUSE_SERVICE,
    MODULE_NAME_ATTACHMENT,
    uploadAttachmentTableCol,
    CREATE_PAGE_TITLE_PREFIX,
    CREATE_PAGE_TITLE_SUFFIX,
    ORDERS_STATUS_READY_STATUS,
  }
}
